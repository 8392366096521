import axios from 'axios';

const axiosInstance = (() => {
    try {
        const instance = axios.create({
            baseURL: 'https://nginx-nest-js.hsepbx.easypanel.host/',
        });
        return instance;
    } catch (error) {
        return {
            data: {
                success: false, message: error
            }
        }
    }
})();

export default axiosInstance;
